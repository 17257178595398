
import Vue from 'vue';
import { siteUrl } from "@/data/helpers";

const redirectionsList = {
  'depression': '/topic/depression/',
  'anxiety': '/topic/anxiety/',
  'mental-health': '/topic/mental-health/',
  'chronic-illness': '/topic/chronic-illness/',
  'disability': '/topic/disability/',
  'suicide': '/topic/suicide/',
  'chronic-pain': '/topic/chronic-pain/',
}

export default Vue.extend({
  name: 'WildcardSlugPage',
  components: {},
  data() {
    return {
      error: {
        statusCode: 404,
        message: 'Page is not found'
      }
    }
  },
  async fetch() {
    const slug = this.$route.params.slug;

    if (redirectionsList[slug]) {
      const url = siteUrl(redirectionsList[slug]);

      if (process.server) {
        return this.$nuxt.context.redirect(301, siteUrl(url));
      }

      return this.$router.replace(url);
    }
  },
});
