import { render, staticRenderFns } from "./_slug.vue?vue&type=template&id=0372b2f5&"
import script from "./_slug.vue?vue&type=script&lang=js&"
export * from "./_slug.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Error: require('/app/nuxtjs/components/Error.vue').default})
