
import { get } from 'lodash';
const statusCodes = {
  401: 'Unauthorized',
  404: 'Not Found',
  500: 'Internal Server Error',
};

export default {
  props: {
    error: {
      type: Object,
      default() {
        return {
          title: '',
          message: '',
          statusCode: 500,
        }
      },
    }
  },
  computed: {
    errorMessage() {
      return get(this, 'error.message');
    },
    statusCode() {
      return this.error.statusCode;
    },
    statusTitle() {
      return this.statusCode in statusCodes ? statusCodes[this.statusCode] : 'Oops something went wrong!';
    }
  },
  head() {
    return {
      title: `${this.statusCode} ${this.statusTitle}`,
    }
  },
  fetch() {
    if (process.server) {
      this.$nuxt.context.res.statusCode = this.statusCode;
    }
  }
}
